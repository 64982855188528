html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Oskari G2, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
  helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
  sans-serif;
}

.mapboxgl-popup-content {
  background: transparent;
  border-radius: 0%;
  box-shadow: none;
  padding: 20px 20px 20px 20px
}

.mapboxgl-popup-tip {
  background: transparent;
}

.mapboxgl-map {
  font-family: Oskari G2, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
  helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
  sans-serif;
}

.mapboxgl-ctrl-group{
  border-radius: 4px;
}

@font-face {
  font-family: "Hanson";
  src: url("../src/assets/Hanson-Bold.ttf");
}

@font-face {
  font-family: "Oskari G2";
  src: url("../src/assets/OskariG2-Book.ttf");
}